<template>
  <header
    class="site__header align-items-center"
    :class="{ 'is-light': hasLightTheme, 'is-active': isShowSideBar }"
    data-ui-navigation="true"
  >
    <router-link class="header__logo" :to="$localePath('home')">
      <img class="mt-0" :src="require(`@/assets/img/${logo}`)" alt="Exandria" />
    </router-link>
    <nav ref="header" class="header__nav" @click="selectSideBarMenuItem">
      <ul class="list-unstyled mb-0 d-flex nav-ul">
        <li
          v-if="!hasLightTheme && loggedIn"
          class="nav-search-field mr-auto"
          :class="{ active: showSearch }"
        >
          <form @submit.prevent="findBooks">
            <input
              v-model="queryString"
              type="text"
              class="form-control__underline"
              :placeholder="$t('Title, author, ISBN')"
              aria-label="Search"
            />
            <button
              class="search-button"
              :class="{ 'btn--disabled': !isSearchAvailable }"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.8319 15.6482C3.51768 15.6482 0.0078125 12.1383 0.0078125 7.82409C0.0078125 3.50986 3.51768 0 7.8319 0C12.1461 0 15.656 3.50986 15.656 7.82409C15.656 12.1383 12.1461 15.6482 7.8319 15.6482ZM7.8319 1.48324C4.33553 1.48324 1.49105 4.32772 1.49105 7.82409C1.49105 11.3205 4.33553 14.1649 7.8319 14.1649C11.3283 14.1649 14.1727 11.3205 14.1727 7.82409C14.1727 4.32772 11.3283 1.48324 7.8319 1.48324ZM18.7761 18.7828C19.0657 18.4931 19.0657 18.0235 18.7761 17.7339L15.4573 14.4152C15.1677 14.1256 14.6981 14.1256 14.4085 14.4152C14.1189 14.7048 14.1189 15.1744 14.4085 15.464L17.7273 18.7828C17.8721 18.9276 18.0619 19 18.2517 19C18.4415 19 18.6313 18.9276 18.7761 18.7828Z"
                />
              </svg>
            </button>
          </form>
        </li>
        <router-link :to="$localePath('home')" tag="li" class="nav-home-tab">
          <a
            :class="{ 'is-active_tab': routeName === 'home' }"
            v-text="$t('Home')"
          ></a>
        </router-link>
        <router-link :to="$localePath('about')" tag="li" class="nav-about-tab">
          <a
            :class="{ 'is-active_tab': routeName === 'about' }"
            v-text="$t('About')"
          ></a>
        </router-link>
        <li class="nav-lang-tab" tabindex="0">
          <language-menu
            :class="{ 'is-light': hasLightTheme, 'is-sidebar': isShowSideBar }"
          />
        </li>
        <li v-if="loggedIn" class="nav-login-tab">
          <button
            class="btn btn--primary btn-sm btn--logout"
            @click="logout"
            v-text="$t('Log Out')"
          ></button>
        </li>
        <li v-else class="nav-login-tab-wrapper">
          <router-link :to="$localePath('login')" tag="li" class="btn__login">
            <a class="t-bold" v-text="$t('Log In')"></a>
          </router-link>
          <router-link :to="$localePath('signup')" tag="li" class="sign-up-btn">
            <button
              class="
                btn btn--primary
                w-100
                d-block d-lg-inline-block
                mt-auto
                btn-sm
              "
              role="link"
              @click="closeSideBarMenu"
              v-text="$t('Sign Up')"
            ></button>
          </router-link>
        </li>
        <li v-if="loggedIn" class="nav-avatar-tab" @click="closeSideBarMenu">
          <router-link :to="$localePath('profile')" class="profile__image">
            <img :src="user.avatar()" class="pr_img" alt="User avatar" />
          </router-link>
        </li>
      </ul>
    </nav>
    <button
      class="header__hamburger d-lg-none"
      data-ui-hamburger="true"
      @click="toggleSideBarMenu"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  </header>
</template>

<i18n src="./translations.json"></i18n>

<script>
import { mapGetters } from 'vuex';
import authService from '@/services/authService';
import LanguageMenu from '@/components/languageMenu/LanguageMenu';

export default {
  components: {
    LanguageMenu,
  },

  data() {
    return {
      auth: authService,
      isShowSideBar: false,
      queryString: this.$route.query.queryString || '',
    };
  },

  computed: {
    ...mapGetters({
      loggedIn: 'auth/loggedIn',
      user: 'user/userModel',
    }),

    hasLightTheme() {
      return this.$route.meta.theme === 'Light';
    },

    showSearch() {
      return this.routeName !== 'home';
    },

    logo() {
      return this.hasLightTheme ? 'logo-light.svg' : 'logo-dark-1.svg';
    },

    routeName() {
      return this.$route.name;
    },

    isSearchAvailable() {
      const qs = this.queryString.trim();
      return qs !== '' && qs !== this.$route.query.queryString;
    },
  },

  watch: {
    $route(to, from) {
      if (from.name === 'home') {
        this.queryString = to.query.queryString;
      }
    },
  },

  methods: {
    logout() {
      this.auth.logout();
      this.$router.push({ name: 'login' });
      this.closeSideBarMenu();
    },

    toggleSideBarMenu() {
      this.isShowSideBar = !this.isShowSideBar;
    },

    closeSideBarMenu() {
      this.isShowSideBar = false;
    },

    selectSideBarMenuItem(e) {
      const { tagName } = e.target;
      const isMatchReg = /A/.test(tagName);

      if (this.isShowSideBar && isMatchReg) {
        this.closeSideBarMenu();
      }
    },

    findBooks() {
      if (!this.isSearchAvailable) {
        return null;
      }

      this.closeSideBarMenu();

      const route = this.$localePath('books', null, {
        queryString: this.queryString,
      });

      return this.$router.push(route);
    },
  },
};
</script>

<style lang="scss">
@import './_header.scss';
</style>

asm,dhjsaljdbasdsakjdbaskdjaskfhaskjjaefheklfheljklsdjs,kdasl;ds,fjl;sdk
