import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=6f88a77a&"
var script = {}
import style0 from "./AppFooter.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./translations.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fsprings-apps%2Fexandria%2Fexandria-front-end%2Fsrc%2Flayouts%2Fdefault%2Fappfooter%2FAppFooter.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports