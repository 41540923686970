<template>
  <div class="d-flex flex-column min-vh-100">
    <app-header />
    <div class="flex-1">
      <vue-page-transition name="fade-in-up">
        <router-view></router-view>
      </vue-page-transition>
    </div>
    <app-footer />
  </div>
</template>

<script>
import AppHeader from './appheader/AppHeader';
import AppFooter from './appfooter/AppFooter';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>
