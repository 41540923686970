<template>
  <div class="language-menu">
    <button
      class="btn language-menu__button t-capitalize"
      :aria-expanded="show"
      :class="{ open: show }"
      @click="show = !show"
      v-text="selected.code"
    ></button>
    <transition
      enter-active-class="transition-all duration-150 ease-out"
      leave-active-class="transition-all duration-150 ease-in"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <ul
        v-if="show"
        ref="menu"
        class="language-menu__dropdown"
        tabindex="-1"
        aria-labelledby="options-menu"
      >
        <li
          v-for="locale in locales.supported"
          :key="locale.code"
          class="language-menu__item"
        >
          <!-- Need to reload the page to set language to the places search lib-->
          <a
            class="locale text-sm font-regular w-full"
            :class="{ active: locale.code === selected.code }"
            :href="
              $router.resolve($localePath(
                $route.name,
                { ...$route.params, lang: locale.code },
                $route.query
              )).href
            "
            @click.native="close"
          >
            {{ locale.code }}
          </a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      show: false,
    };
  },

  computed: {
    ...mapGetters({
      locales: 'locales',
    }),

    selected() {
      return this.locales.supported.find(
        (locale) => locale.code === this.$i18n.locale
      );
    },
  },

  mounted() {
    document.addEventListener('click', this.clickAway);
    document.addEventListener('touchstart', this.clickAway);
  },

  beforeDestroy() {
    document.removeEventListener('click', this.clickAway);
    document.removeEventListener('touchstart', this.clickAway);
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),

    close() {
      if (!this.show) return;

      this.show = false;
    },

    clickAway(e) {
      if (this.$el.contains(e.target)) {
        return;
      }

      this.close();
    },
  },
};
</script>

//
<style lang="scss" scoped>
@import './languageMenu.scss';
</style>
